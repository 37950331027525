import React from "react"
import { graphql } from "gatsby"

import Layout from "../../layouts/en"
import ArticleTemplate from "./_article"

const BlogArticleEnglish = props => {
  const { title, excerpt, image } = props.data.post

  return (
    <Layout
      location={props.location}
      matchingSlug={`/${props.pageContext.matchingSlug}`}
      title={title}
      description={excerpt}
      banner={image?.url}
      article
    >
      <ArticleTemplate {...props} />
    </Layout>
  )
}

export default BlogArticleEnglish

export const pageQuery = graphql`
  query ArticleEnglish($id: String!) {
    post: blogPostEn(postId: { eq: $id }) {
      ...BlogPostEnFragment
    }
  }
`
